// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


/*******************************************************************************
* FOOTER
*******************************************************************************/

.section-footer {
    padding: 150px 0 0 0;
    background: #000;
    position: relative;

    footer {
        background: url("../images/acceuil_footer_bg.jpg") no-repeat;
        background-size: cover;
        height: 389px;
        #blocFooter {
            position: absolute;
            left: 15%;
            top: 55%;
            width: 70%;
            .menu-logo {
                padding-bottom: 45px;
                border-bottom: 2px solid #343434;
                display: flex;
                align-items: flex-end;
                .left-side {
                    width: 75%;
                }
                .blocLogo {
                    display: flex;
                    align-items: flex-end;
                    a.lienIcon {
                        img.img-responsive.hover {
                            display: none;
                        }
                        :hover {
                            img.img-responsive.hover {
                                display: block;
                            }
                            img.img-responsive.defaut {
                                display: none;
                            }
                        }
                    }
                    .logo.logoPrincipal {
                        padding-right: 40px;
                    }
                    .logo.icon {
                        padding-right: 20px;
                    }
                }
                a {
                    font-size: $font-size-18;
                    font-weight: 700;
                    color: #fff;
                    padding-right: 3%;
                    &:hover {
                        color: #28539b;
                    }
                }
            }
        }
        .section-copyright {
            display: flex;
            padding-top: 45px;
            a {
                font-size: $font-size-14;
                font-weight: normal;
                color: #fff;
                &:hover {
                    color: #28539b;
                }
                img {
                    margin-top: -10px;
                }
            }
            div.copyright, span {
                font-size: $font-size-14;
                font-weight: normal;
                color: #fff;
            }
            .copyright {
                padding-right: 5%;
            }
        }
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-burst {
    background: #000;
    padding-bottom: 200px;
    .blocBurst {
        display: flex;
        background: #fff;
        width: 65%;
        border-radius: 10px;
        position: absolute;
        margin: -150px auto;
        z-index: 30;
        left: 18%;
        a.burst {
            width: 34%;
            text-align: center;
            transition: 0.3s;
            .bloc {
                padding: 30px 0;
                .img-responsive {
                    margin: auto;
                }
                .contenu {
                    border-right: 2px solid #f1f1f1;
                }
                .contenu.right {
                    border-right: none;
                }
                h3 {
                    font-size: $font-size-48;
                    font-weight: 900;
                    color: #000;
                    padding-top: 25px;
                    padding-bottom: 5px;
                }
                h4 {
                    font-size: $font-size-28;
                    font-weight: normal;
                    color: #000;
                    text-transform: uppercase;
                }
            }
        }
    }
    a.burst.left:hover {
        background: #f1f1f1;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: inset 0 0 15px 0 black;
        transition: 0.3s;
        .contenu {
            border-right: none; 
        }  
    }
    a.burst.center:hover {
        background: #f1f1f1;
        box-shadow: inset 0 0 15px 0 black; 
        transition: 0.3s;
        .contenu {
            border-right: none; 
        } 
    }
    a.burst.right:hover {
        background: #f1f1f1;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: inset 0 0 15px 0 black;
        transition: 0.3s;    
    }
}

#blocIntro_accueil {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #000;
    padding: 100px 2%;
    .left-side {
        width: 45%;
        .texte {
            text-align: justify;
        }
    }
    h3 {
        font-size: $font-size-60;
        font-weight: 900;
        color: #fff;
        padding-bottom: 15px;
    }
    h4 {
        font-size: $font-size-26;
        font-weight: normal;
        color: #fff;
        text-transform: uppercase;
        padding-bottom: 45px;
    }
    p {
        font-size: $font-size-20;
        font-weight: normal;
        color: #fff;
    }
}

#blocCarousel {
    position: relative; 
    background: #000;
    padding: 90px 0;

    .main-content {
        position: relative;
        .owl-theme {
            .custom-nav {
                position: absolute;
                top: 20%;
                left: 0;
                right: 0;
            }
        }
    }
}

#bloc50-50_accueil {
    background: url("../images/acceuil_section04_paralax_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment : fixed;
    background-position: center;
    display: flex;
    padding: 100px 2%;
    justify-content: space-around;
    .blocListe {
        display: flex;
        padding-top: 50px;
        .left {
            padding-right: 110px;
        }
        ul {
            padding-left: 35px;
        }
        li {
            font-size: $font-size-24;
            font-weight: normal;
            color: #fff;
            text-transform: initial;
            line-height: 1.5;
            padding-bottom: 20px;
        }
    }
    h3 {
        font-size: $font-size-60;
        font-weight: 900;
        color: #fff;
    }
}

/*******************************************************************************
* TRANSPORT & REMORQUAGE
*******************************************************************************/

#bloc50-50_transport {
    display: flex;
    background: #000;
    justify-content: center;
    padding: 100px 2%;
    .left-side {
        width: 45%;
        margin-right: 5%;
        h3 {
            font-size: $font-size-60;
            font-weight: 900;
            color: #fff;
        }
        p {
            font-size: $font-size-20;
            font-weight: normal;
            color: #fff;
            line-height: 1.7;
            padding: 30px 0;
        }
        a.btnTransport {
            background: #0054a6;
            color: #ffff;
            padding: 15px 50px;
            border-radius: 10px;
            font-size: $font-size-24;
            font-weight: normal;
            transition: 0.3s;
            &:hover {
                color: #000;
                transition: 0.3s;
            }
        }
    }
}

#blocBackground {
    background: url("../images/transport_section02_bg_paralax.jpg");
    background-size: cover;  
    text-align: center;
    padding: 195px 2%;
    p {
        font-size: $font-size-26;
        font-weight: normal;
        color: #fff; 
    }
}

#blocListe {
    background: #000;
    display: flex;
    justify-content: space-around;
    padding: 100px 5%;
    .liste {
        display: flex;
    }
    h3 {
        font-size: $font-size-60;
        font-weight: 900;
        color: #fff;
        padding-bottom: 20px;
    }
    ul {
        li {
            font-size: $font-size-24;
            font-weight: normal;
            color: #fff;
            line-height: 1.5;
            padding-bottom: 15px;
            text-transform: initial;
        }
    }
    .left-side {
        width: 50%;
        .left {
            width: 45%;
        }
    }
    .right-side {
        width: 40%;
        .left {
            width: 55%;
        }
    }
}

#blocImages {
    display: flex;
    flex-wrap: wrap;
    width: 112%;
    .image {
        width: 15%;
    }
}

#blocBesoin {
    background: #000;
    padding: 100px 0 1px 0;
    .contenu {
        width: 60%;
        margin: auto;
        .titre {
            background: #0054a6;
            text-align: center;
            padding: 10px;
            border-radius: 30px;
            h3 {
                font-size: $font-size-60;
                font-weight: bold;
                color: #fff;
            }
        }
        p {
            font-size: $font-size-24;
            font-weight: normal;
            color: #fff;
            line-height: 1.5;
            padding: 30px 0px 0 5%;
        }
        h4 {
            font-size: $font-size-36;
            font-weight: bold;
            color: #0054a6;
            padding-left: 5%;
        }
        .blocEtape {
            padding: 50px 0 0 5%;
            p {
                font-size: $font-size-24;
                font-weight: normal;
                color: #fff;
                padding: 0;
                margin: 0;
                line-height: 1.3;
                span {
                    font-size: $font-size-60;
                    font-weight: normal;
                    color: #0054a6;
                    padding-right: 10px;
                }
            }
        }
    }
}

/*******************************************************************************
* FOURRIÈRE
*******************************************************************************/

#blocIntro_fourriere {
    background: #000;
    padding: 100px 5%;
    h3 {
        font-size: $font-size-60;
        font-weight: 900;
        color: #fff;
    }
    p {
        font-size: $font-size-20;
        font-weight: normal;
        color: #fff;
        padding: 30px 0 0 0;
    }
    h4 {
        font-size: $font-size-26;
        font-weight: bold;
        color: #ee2e24;
        span {
            font-size: $font-size-60;
            padding-right: 10px;
            font-weight: bold;
        }
    }
}

#blocFourriere {
    background: #000;
    padding: 0 0 1px 0;
    .contenu {
        width: 63%;
        margin: auto;
        .titre {
            background: #0054a6;
            text-align: center;
            padding: 15px;
            border-radius: 30px;
            h3 {
                font-size: $font-size-60;
                font-weight: bold;
                color: #fff;
            }
        }
        .image-texte.first {
            padding-bottom: 0;
        }
        .image-texte {
            display: flex;
            padding: 50px 10% 90px 10%;
            .left-side {
                width: 25%;
            }
            p {
                font-size: $font-size-24;
                font-weight: normal;
                color: #fff;
                margin: 0;
            }
        }
        .blocEtape {
            padding: 50px 10%;
            .bloc {
                p {
                    font-size: $font-size-24;
                    font-weight: normal;
                    color: #fff;
                    padding: 0;
                    margin: 0;
                    line-height: 1.3;
                    span {
                        font-size: $font-size-60;
                        font-weight: normal;
                        color: #0054a6;
                        padding-right: 10px;
                    }
                }
                ul {
                    padding-left: 15%;
                    list-style: none;
                    li {
                        font-size: $font-size-30;
                        font-weight: normal;
                        color: #fff; 
                        text-transform: uppercase;
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}

#blocFourriere .blocAccesFourriere .blocEtape .bloc ul li::before {
    content: "\2022";  
    color: #0054a6;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;                    
}

/*******************************************************************************
* PROCÉDURES
*******************************************************************************/

#blocProcedures {
    background: #000;
    padding: 0 0 1px 0;
    .blocIntro {
        width: 80%;
        margin: auto;
        padding: 15px 0 50px 0;
        h4 {
            font-size: $font-size-48;
            font-weight: bold;
            color: #ee2e24;
            span {
                font-size: $font-size-60;
                padding-right: 10px;
                font-weight: bold;
            }
        }
    }
    p {
        font-size: $font-size-24;
        font-weight: normal;
        color: #fff;
        padding: 0;
        margin: 0;
        line-height: 1.3;
        span {
            font-size: $font-size-60;
            font-weight: normal;
            color: #0054a6;
            padding-right: 10px;
        }
    }
    ul {
        padding-left: 17%;
        list-style: none;
        li {
            font-size: $font-size-30;
            font-weight: normal;
            color: #fff; 
            text-transform: uppercase;
            line-height: 1.5;
        }
    }
    h4 {
        font-size: $font-size-32;
        font-weight: bold;
        color: #0054a6; 
        padding: 35px 0 10px 10%;
    }
    .blocAccesFourriere {
        li {
            text-transform: uppercase !important;
        }
    }
    .bloc {
        h4 {
            font-size: $font-size-32;
            font-weight: bold;
            color: #0054a6; 
            text-transform: uppercase;
        }
        li {
            text-transform: initial;
        }
        p {
            padding-left: 10%;
        }
        p.info {
            padding-top: 35px;
        }
        p.last {
            padding-bottom: 50px;
        }
    }
    .contenu {
        width: 63%;
        margin: auto;
        .titre {
            background: #0054a6;
            text-align: center;
            padding: 15px;
            border-radius: 30px;
            h3 {
                font-size: $font-size-60;
                font-weight: bold;
                color: #fff;
            }
        }
        .image-texte.first {
            padding-bottom: 0;
        }
        .image-texte {
            display: flex;
            padding: 50px 10% 90px 10%;
            .left-side {
                width: 25%;
            }
            p {
                font-size: $font-size-24;
                font-weight: normal;
                color: #fff;
                margin: 0;
            }
        }
        .blocEtape {
            padding: 10px 2% 50px 2%;
        }
    }
    .blocTel {
        width: 70%;
        margin: auto;
        h4 {
            text-transform: uppercase;
            font-size: $font-size-48;
        }
        .telephone {
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
                width: 5%;
            }
            h3 {
                text-transform: uppercase;
                font-size: $font-size-60;
                color: #fff;
                font-weight: bold;
            }
        }
    }
}

#blocProcedures .blocAccesFourriere .blocEtape .bloc ul li::before, #blocProcedures .bloc ul li::before {
    content: "\2022";  
    color: #0054a6;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;                    
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#blocIntro_emploi {
    background: #000;
    padding: 100px 5% 50px 5%;
    h2 {
        font-size: $font-size-60;
        font-weight: 900;
        color: #fff; 
        text-transform: uppercase;
        padding-bottom: 20px;
    }
    p {
        font-size: $font-size-20;
        font-weight: normal;
        color: #fff; 
    }
}

#blocEmploi {
    background: #000;
    padding: 15px 5%;
    .contenu {
        width: 63%;
        margin: auto;
        .titre {
            background: #0054a6;
            text-align: center;
            padding: 15px;
            border-radius: 30px;
            h3 {
                font-size: $font-size-60;
                font-weight: bold;
                color: #fff;
            }
        }
        .emploi {
            padding: 50px 2% 25px 2%;
            h3 {
                font-size: $font-size-36;
                font-weight: bold;
                color: #0054a6;
                text-transform: uppercase;
                padding-bottom: 10px;
            }
            p {
                font-size: $font-size-24;
                font-weight: normal;
                color: #fff;
                line-height: 1.7;
                margin: 0;
                padding-bottom: 35px;
            }
            .btnPostulez {
                background: #0054a6;
                color: #fff;
                padding: 15px 60px;
                border-radius: 10px;
                font-size: $font-size-26;
                font-weight: normal;
                text-transform: uppercase;
                border: none;
                transition: 0.3s;
                &:hover {
                    color: #000;
                    transition: 0.3s;
                }
            }
        }
        .section-form {
            .module-formulaire {
              display: none;
                h2.formtitre {
                    font-size: $font-size-32;
                    font-weight: bold;
                    color: #0054a6;
                    padding-bottom: 15px;
                }
                label.image {
                    font-size: $font-size-26;
                    font-weight: bold;
                    color: #0054a6;
                    border-bottom: 1px solid #0054a6;
                    margin-bottom: 25px;
                }
                label.colorform {
                    font-size: $font-size-26;
                    font-weight: normal;
                    color: #fff;
                    text-transform: initial;
                }
                input {
                    width: 90%;
                    height: 35px;
                    padding-left: 10px;
                }
                form textarea {
                    height: 200px !important;
                    width: 90%;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#blocRealisations {
    background: #000;
    padding: 100px 5% 0 5%;
    .realisations.zoom-gallery {
        display: flex;
        flex-wrap: wrap;
        a.item {
            width: 33.3333%;
            padding: 25px;
            .bloc {
                h3 {
                    font-size: $font-size-36;
                    color: #0054a6;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding-top: 35px; 
                    padding-bottom: 5px; 
                }
                p {
                    font-size: $font-size-22;
                    color: #fff;
                    font-weight: normal;  
                    line-height: 1.5;
                    text-align: justify;
                    width: 90%;
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocCoodonnees {
    background: #000;
    display: flex;
    justify-content: space-around;
    padding: 100px 0;
    .bloc {
        display: flex;
        .icon {
            padding-right: 20px;
        }
        h3 {
            font-size: $font-size-36;
            color: #fff;
            font-weight: normal;
            padding-bottom: 15px;
        }
        p {
            font-size: $font-size-24;
            color: #fff;
            font-weight: normal;
            margin: 0;
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1880px) {
    
#section-burst .blocBurst {
    width: 68%;
}
#section-burst .blocBurst a.burst .bloc h3 {
    font-size: 2.2rem;
}
#section-burst .blocBurst a.burst .bloc h4 {
    font-size: 1.2rem;
}
}

@media screen and (max-width: 1705px) {

#blocBackground p br, #blocBesoin .contenu p br {
    display: none;
}
}

@media screen and (max-width: 1700px) {

#blocIntro_accueil .left-side {
    width: 50%;
    padding-right: 35px
}
#blocIntro_accueil .right-side {
    width: 32%;
}
#blocProcedures .contenu {
    width: 70%;
}
#blocProcedures .blocTel {
    width: 80%;
}
#blocProcedures .blocIntro {
    width: 85%;
}
}

@media screen and (max-width: 1650px) {
    
#section-burst .blocBurst {
    margin: -100px auto;
}
}

@media screen and (max-width: 1625px) {
    
#section-burst .blocBurst a.burst .bloc h3 {
    font-size: 2rem;
}
.section-footer footer #blocFooter {
    left: 10%;
    width: 80%;
}
}

@media screen and (max-width: 1605px) {

#blocIntro_accueil h3 {
    font-size: 2.8rem;
}
#blocIntro_accueil h4 {
    font-size: 1.2rem;
}
.module-header--caption {
    top: 68%;
    left: 2%;
}
#blocFourriere .contenu {
    width: 80%;
}
}

@media screen and (max-width: 1575px){

#blocProcedures .blocIntro {
    width: 90%;
}
#blocProcedures .contenu {
    width: 75%;
}
#blocProcedures .contenu .titre h3 {
    font-size: 2.8rem;
}
}

@media screen and (max-width: 1530px) {
    
#blocIntro_accueil p br {
    display: none;
}
#blocIntro_accueil .left-side {
    width: 55%;
    padding-right: 65px;
}
}

@media screen and (max-width: 1495px) {
    
#section-burst .blocBurst {
    margin: -85px auto;
}
#section-burst .blocBurst a.burst .bloc h3 {
    font-size: 1.8rem;
}
#section-burst .blocBurst a.burst .bloc h4 {
    font-size: 1rem;
}
#blocListe .right-side .left {
    width: 65%;
}
#blocProcedures .blocIntro {
    width: 100%;
}
#blocProcedures .blocTel {
    width: 90%;
}
}

@media screen and (max-width: 1420px) {
    
nav.module-menu .menu-navbar.nav-visible, nav.module-menu .menu-navbar.nav-invisible {
    padding: 0 3%;
}
#section-burst .blocBurst {
    margin: -65px auto;
}
#section-burst .blocBurst a.burst .bloc h3 {
    font-size: 1.6rem;
}
#bloc50-50_accueil .blocListe .left {
    padding-right: 50px;
}
.section-footer footer #blocFooter {
    width: 85%;
}
#blocIntro_accueil .left-side {
    width: 60%;
}
#blocEmploi .contenu {
    width: 70%;
}
#bloc50-50_transport .left-side {
    width: 50%;
    margin-right: 3%;
}
#blocListe ul li {
    font-size: 1.1rem;
}
#blocBesoin .contenu {
    width: 65%;
}
#blocFourriere .contenu .titre h3 {
    font-size: 2.7rem;
}
}

@media screen and (max-width: 1360px) {
    
nav.module-menu .menu-navbar.nav-visible, nav.module-menu .menu-navbar.nav-invisible {
    padding: 0 2%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div, nav.module-menu .menu-navbar .wrapper.right .main-menu-top > ul li div {
    font-size: 0.9rem;
}
#blocCoodonnees {
    flex-direction: column;
    padding: 100px 3% 50px 3%;
}
#blocCoodonnees .bloc {
    padding-bottom: 40px;
}
#blocProcedures .contenu {
    width: 80%;
}
#blocProcedures .blocIntro {
    padding: 50px 2%;
}
#blocProcedures .blocIntro h4 {
    font-size: 2.2rem;
}
#blocProcedures .contenu .titre h3 {
    font-size: 2.5rem;
}
#blocProcedures p, #blocProcedures .contenu .image-texte p {
    font-size: 1.1rem;
}
#blocProcedures h4 {
    padding: 35px 0 10px 6%;
}
#blocProcedures .blocTel {
    width: 100%;
    padding: 0 2%;
}
#blocProcedures .blocTel h4 {
    padding-left: 0;
    text-align: center;
}
}

@media screen and (max-width: $size-md-max) {
    
#section-burst .blocBurst {
    margin: -50px auto;
}
#blocIntro_accueil {
    padding: 150px 5%;
}
#blocIntro_accueil .left-side {
    width: 80%;
}
#blocEmploi .contenu {
    width: 75%;
}
#blocBackground {
    padding: 135px 2%;
}
}

@media screen and (max-width: 1200px) {
  
#section-burst {
    padding-bottom: 0px;
    margin: -1px;
}
#section-burst .blocBurst {
    position: initial;
    margin: auto;
}
#section-burst .blocBurst a.burst .bloc h3 {
    font-size: 1.4rem;
}
.section-footer {
    padding: 0px 0 0 0;
}
.section-footer footer {
    background: #101010;
    height: 300px;
}
.section-footer footer #blocFooter {
    width: 100%;
    position: initial;
    padding: 0 5%;
}
#blocProcedures .contenu {
    width: 85%;
}
#blocProcedures {
    padding: 0 2% 100px 2%;
}
}

@media screen and (max-width: 1160px) {

.section-footer footer #blocFooter .menu-logo {
    flex-direction: column;
    align-items: baseline;
}
.section-footer footer {
    height: 375px;
}
.section-footer footer #blocFooter .menu-logo .left-side {
    width: 100%;
    padding-bottom: 38px;
}
#blocFourriere .contenu {
    width: 85%;
}
#blocProcedures .contenu {
    width: 90%;
}
#blocProcedures .blocIntro h4 {
    padding-left: 0;
    text-align: center;
}
}

@media screen and (max-width: 1024px) {
  
.texteBanniere {
    position: absolute;
    left: -20%;
    top: -80%;
}
#section-burst .blocBurst {
    width: 83%;
}
#bloc50-50_accueil .blocListe .left {
    padding-right: 10px;
}
#blocIntro_accueil {
    flex-direction: column;
    padding: 100px 5% 0px 5%;
    margin-bottom: -2px;
}
#blocIntro_accueil .left-side {
    width: 100%;
    padding-right: 0;
}
#blocIntro_accueil .right-side {
    width: 65%;
    padding-top: 35px;
}
#blocEmploi .contenu {
    width: 85%;
}
#blocEmploi .contenu .emploi p {
    padding-bottom: 15px;
}
.module-formulaire .col-sm-8 {
    width: 80%;
}
#blocRealisations .realisations.zoom-gallery a.item {
    width: 50%;
}
#blocListe {
    flex-direction: column;
}
#blocListe .left-side {
    width: 100%;
    padding-bottom: 40px;
}
#blocListe .right-side {
    width: 100%;
}
#blocListe .right-side .left {
    width: 45%;
}
#blocImages {
    width: 100%;
}
#blocImages .image {
    width: 25%;
}
#blocBesoin .contenu {
    width: 85%;
}
#blocBesoin {
    padding: 100px 0;
}
#blocIntro_fourriere h3 {
    font-size: 2.8rem;
}
#blocFourriere .contenu {
    width: 95%;
}
#blocFourriere {
    padding: 0 2% 100px 2%;
}
#blocFourriere .contenu .titre h3 {
    font-size: 2.5rem;
}
#blocFourriere .contenu .image-texte p, #blocFourriere .contenu .blocEtape .bloc p {
    font-size: 1.1rem;
}
#blocIntro_fourriere p br {
    display: none;
}
#blocProcedures ul li {
    font-size: 1.2rem;
}
#blocProcedures .blocTel .telephone .icon {
    width: 7%;
}
#blocProcedures .bloc h4 {
    font-size: 1.4rem;
}
#blocProcedures .contenu .image-texte .left-side {
    width: 28%;
}
#blocProcedures .blocIntro {
    padding: 25px 2% 50px 2%;
}
#blocCoodonnees {
    padding: 50px 3%;
}
#blocIntro_emploi, #blocRealisations {
    padding: 50px 5%;
}
}

@media screen and (max-width: $size-sm-max) {

#section-burst {
    padding-top: 50px;
} 
.slideshow-wrap .bouton {
    width: 35%;
    margin-top: 25px;
}
.texteBanniere {
    top: -100%;
}
#blocCarousel {
    padding: 90px 0 0 0;
}
#bloc50-50_accueil .blocListe .left {
    padding-right: 0;
}
#blocRealisations {
    padding: 100px 5% 50px 5%;
}
#bloc50-50_transport {
    flex-direction: column;
}
#bloc50-50_transport .left-side {
    width: 100%;
    margin-right: 0;
    padding-bottom: 65px;
}
#bloc50-50_transport .right-side .img-responsive {
    margin: auto;
}
#blocImages {
    width: 101%;
}
#blocImages .image {
    width: 33%;
}
#blocBesoin .contenu {
    width: 95%;
}
.module-header--caption--title {
    font-size: 2.8rem;
}
#blocFourriere .contenu {
    width: 100%;
}
#blocFourriere .contenu .image-texte {
    padding: 50px 5% 90px 5%;
}
#blocFourriere .contenu .image-texte .left-side {
    width: 30%;
    padding-right: 25px;
}
#blocFourriere .contenu .blocEtape {
    padding: 50px 5%;
}
#blocProcedures .contenu {
    width: 100%;
}
#blocProcedures .bloc p {
    padding-left: 7%;
}
#blocProcedures .contenu .blocEtape {
    padding: 10px 0% 50px 0%;
}
#blocProcedures .contenu .image-texte {
    padding: 50px 5% 90px 4%;
}
#blocProcedures .contenu .image-texte .left-side {
    width: 33%;
}
}

@media screen and (max-width: $size-xs-max) {

#section-burst .blocBurst {
    width: 85%;
    flex-direction: column;
}
#section-burst .blocBurst a.burst {
    width: 80%;
    margin: auto;
}
#section-burst .blocBurst a.burst .bloc {
    padding: 30px 0 0 0;
}
#section-burst .blocBurst a.burst .bloc .contenu {
    border-right: none;
    border-bottom: 4px solid #f1f1f1;
    padding-bottom: 35px;
}
#section-burst .blocBurst a.burst .bloc .contenu.right {
    border-bottom: none;
}
#section-burst a.burst.left:hover, #section-burst a.burst.center:hover, #section-burst a.burst.right:hover {
    width: 100%;
}
#section-burst .blocBurst a.burst .bloc h3 {
    font-size: 2.4rem;
}
#section-burst .blocBurst a.burst .bloc h4 {
    font-size: 1.4rem;
}
.texteBanniere {
    position: initial;
}
.slideshow-wrap .title h2 {
    font-size: 2.6rem;
}
.slideshow-wrap .bouton {
    margin-top: 40px;
}
#bloc50-50_accueil {
    flex-direction: column;
    padding: 75px 5%;
}
#bloc50-50_accueil .left-side {
    padding-bottom: 30px;
}
#bloc50-50_accueil .blocListe .left {
    padding-right: 30px;
}
#bloc50-50_accueil .blocListe ul {
    padding-left: 25px;
}
#blocFooter .menu-logo .left-side .menu {
    display: flex;
    flex-direction: column;
}
.section-footer footer {
    height: 495px;
}
.section-footer footer #blocFooter .menu-logo a {
    font-size: 1rem;
    padding-right: 0;
    padding-bottom: 15px;
}
.section-footer footer .section-copyright {
    flex-direction: column;
    text-align: center;
}
.section-footer footer .section-copyright div.copyright, .section-footer footer .section-copyright span {
    font-size: 0.8rem;
}
.section-footer footer .section-copyright div.copyright {
    padding-bottom: 15px;
}
#section-burst a.burst.left:hover {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
}
#section-burst a.burst.right:hover {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
}
.slideshow-wrap .bouton a.btnHeader {
    display: none;
}
#blocEmploi .contenu {
    width: 100%;
}
#blocEmploi .contenu .section-form .module-formulaire label.image {
    margin-top: 25px;
}
#blocRealisations .realisations.zoom-gallery a.item {
    width: 100%;
}
#bloc50-50_transport .left-side h3 {
    font-size: 2.8rem;
}
#bloc50-50_transport .left-side p {
    padding: 20px 0;
}
#blocBackground {
    padding: 75px 2%;
    background-position: top;
}
#blocListe .left-side .left {
    width: 60%;
}
#blocListe .right-side .left {
    width: 50%;
}
#blocImages {
    width: 100%;
}
#blocImages .image {
    width: 50%;
}
#blocBesoin {
    padding: 100px 2%;
}
#blocBesoin .contenu {
    width: 100%;
}
#blocFourriere .contenu .image-texte.first, #blocFourriere .contenu .image-texte {
    flex-direction: column;
}
#blocFourriere .contenu .image-texte .left-side {
    width: 45%;
    margin: auto;
    padding-right: 0;
    padding-bottom: 20px;
}
#blocFourriere .contenu .image-texte p, #blocFourriere .contenu .blocEtape .bloc p {
    font-size: 1rem;
}
#blocIntro_fourriere {
    padding: 100px 5% 50px 5%;
}
#blocProcedures .bloc p {
    padding-left: 5%;
}
#blocProcedures .contenu .titre h3 {
    font-size: 2.3rem;
}
#blocProcedures ul {
    padding-left: 15%;
}
#blocProcedures .contenu .image-texte.first {
    padding-bottom: 0;
    flex-direction: column;
}
#blocProcedures .contenu .image-texte {
    flex-direction: column;
}
#blocProcedures .contenu .image-texte .left-side {
    width: 45%;
    margin: auto;
    padding-bottom: 25px;
}
#blocProcedures p, #blocProcedures .contenu .image-texte p {
    font-size: 1.2rem;
}
#blocProcedures .blocTel .telephone .icon {
    width: 15%;
}
#blocProcedures .blocIntro {
    padding: 0 2% 30px 2%;
}
}

@media screen and (max-width: 360px) {

.slideshow-wrap .title h2 {
    font-size: 2.4rem;
}
#blocIntro_emploi h2 {
    text-align: center;
}
#section-burst .blocBurst a.burst .bloc h3 {
    font-size: 2.3rem;
}
#blocCoodonnees .bloc h3 {
    font-size: 1.5rem;
}
#blocListe .right-side .left {
    width: 75%;
}
#blocFourriere .contenu .image-texte p, #blocFourriere .contenu .blocEtape .bloc p {
    padding-bottom: 5px;
}
#blocFourriere .contenu .blocEtape .bloc ul {
    padding-left: 10%;
    padding-top: 10px;
}
#blocProcedures .blocIntro h4 {
    font-size: 2rem;
}
}