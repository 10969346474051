.btn {
    background-color: #0054a6;
    color: $color-white;
    border-radius: 10px;
    padding: 15px 50px;
    font-size: $font-size-22;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        color: #000;
    }
}
