/*****************************************************/
// MENU
/* needs _sources/js/main.js to toggle var menu = ".menu-mobile"; */

nav.module-menu {
    position: relative;
    ul {
        list-style: none;
    }
    &.scrolled {
        .menu-navbar.nav-visible {
            background: #000;
        }
    }
    /****************************************************
    /* Set if the menu is over the slide show or not
    */
    // if menu do NOT overlap the content
    $menu-bottom-padding-desktop : $size-nav-height;
    $menu-bottom-padding-mobile : $size-nav-height-mobile;
    // if menu DO overlap over the content
    $menu-bottom-padding-desktop : 0;
    $menu-bottom-padding-mobile : 0;
    // sets the padding according to context
    padding-bottom: $menu-bottom-padding-desktop;
    @media screen and (max-width: $size-nav-toggle-breakpoint) {
        padding-bottom: $menu-bottom-padding-mobile;
    }

    /************ menu desktop ***************************/
    .menu-navbar {
        @extend .flex;
        position: fixed;
        top: 0;
        z-index: $z-index-menu;
        width: 100%;
        height: 135px;
        transition: all $transition-speed-fast ease;

        @extend .bg-color-nav; // see _colors.scss

        /* purgecss start ignore */
        &.nav-invisible {
            top: -130px;
            transition: all $transition-speed-fast ease;
            padding: 0 5%;
        }
        &.nav-visible {
            top: 0;
            transition: all $transition-speed-fast ease;
            padding: 0 5%;
        }

        @media screen and (max-width: $size-nav-shift-breakpoint) {
            height: 100px;
            transition: all $transition-speed-fast ease;
            &.nav-invisible {
                top: -$size-nav-height-mobile;
                transition: all $transition-speed-fast ease;
            }
            &.nav-visible {
                top: 0;
                transition: all $transition-speed-fast ease;
            }
        }
        /* purgecss end ignore */

        .wrapper {
            &.left {
                flex: 0 0 auto;
                .logo {
                    height: 100%;
                    width: auto;
                    @extend .flex;
                    @extend .justify-center;
                    @extend .items-center;
                    @extend .px6;
                    a {
                        @extend .text-color-nav-logo;
                        @extend .font-nav-logo;
                        &:hover {
                            @extend .text-color-nav-hover;
                        }
                    }
                    .slogan {
                        @extend .text-color-nav;
                        @extend .font-1-normal;
                        font-size: $font-size-12;
                        &:hover {
                            @extend .text-color-nav-hover;
                        }
                    }
                    @media screen and (max-width: $size-nav-shift-breakpoint) {
                        max-width: 220px;
                    }
                }
            }
            &.right {
                flex: auto;
                @extend .flex;
                @extend .flex-column;
                @extend .justify-center;
                @extend .items-end;
                @extend .pr6;
                .main-menu-top, .main-menu {
                    height: 35%;
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        display: none;
                    }
                    & > ul {
                        @extend .flex;
                        @extend .flex-wrap;
                        @extend .justify-end; // pour menu a droite
                        // @extend .justify-center; // pour menu au centre de l'espace
                        // @extend .justify-start; // pour menu a gauche
                        @extend .items-center; // pour menu centré verticalement
                        // @extend .items-start; // pour menu en haut
                        // @extend .items-end; // pour menu en bas
                        @extend .p0;
                        @extend .m0;
                        height: 100%;
                        li:last-child > a {
                            @extend .pr0;
                        }
                        li {
                            height: 100%;
                            @extend .flex;
                            @extend .items-center;
                            // @extend .items-start; // pour menu en haut
                            // @extend .items-end; // pour menu en bas
                            &.icon, &.phone, &.lang {
                                @extend .ml4;
                            }
                            :hover {
                                div {
                                    border-bottom: 2px solid #fff;
                                }
                            }
                            div {
                                color: #fff;
                                font-size: $font-size-20;
                                font-weight: normal;
                            }
                            &.icon {
                                a {
                                    height: 100%;
                                    @extend .flex;
                                    @extend .items-center;
                                }
                                a .hoverable {
                                    @extend .filter-normal;
                                }
                                a:hover .hoverable {
                                    @extend .filter-hover;
                                }
                            }

                            &.phone {
                                & > a, & > .menu-title {
                                    height: 100%;
                                    @extend .font-nav-phone;
                                    @extend .text-color-nav-phone;
                                }
                                &:hover > a, &:hover > .menu-title {
                                    @extend .text-color-nav-phone-hover;
                                }
                                a {
                                    height: 100%;
                                    .icon {
                                        height: 100%;
                                        @extend .flex;
                                        @extend .items-center;
                                        .hoverable {
                                            @extend .filter-normal;
                                        }

                                    }
                                    div.phonenumber {
                                        font-size: $font-size-28;
                                        color: #fff;
                                        font-weight: 900;
                                    }
                                }
                                &:hover > a {
                                    height: 100%;
                                    .icon {
                                        height: 100%;
                                        .hoverable {
                                            @extend .filter-hover;
                                        }
                                    }
                                    div.phonenumber, div.icon {
                                        color: #0054a6;
                                        border: none;
                                    }
                                }
                            }
                            &.lang {
                                a {
                                    border-right: 2px solid #fff;
                                    line-height: 1;
                                    padding-right: 1rem;
                                }
                                & > a, & > .menu-title {
                                    @extend .font-nav-lang;
                                    @extend .text-color-nav-lang;
                                }
                                &:hover > a, &:hover > .menu-title {
                                    @extend .text-color-nav-lang-hover;
                                    transition: all $transition-speed-fast ease;
                                }
                            }
                            &.lang.coordonnees a {
                                border-right: none;  
                            }
                        }
                    }
                }
                .main-menu {
                    & > ul {
                        & > li {
                            &:last-child {
                                margin-right: -1rem;
                                padding-right: 1rem;
                            }
                        }
                        li {
                            position: relative;
                            //transition: all $transition-speed-very-slow ease;
                            &:last-child a {
                                border-right: none;
                            }
                            & > a, & > .menu-title {
                                @extend .px4;
                                @extend .text-color-nav;
                                @extend .font-nav-items;
                                transition: all $transition-speed-very-fast ease;
                                border-right: 2px solid #fff;
                                padding-left: 1rem;
                                padding-right: 1rem;

                                div {
                                    color: #fff;
                                    font-size: $font-size-20;
                                    font-weight: normal;
                                }
                            }
                            span i {
                                font-size: 0.6rem;
                            }
                            &:hover {
                                transition: all $transition-speed-fast ease;
                                & > a, & > .menu-title {
                                    @extend .text-color-nav-hover;
                                }
                                div {
                                    border-bottom: 2px solid #fff;
                                }
                            }

                            &.active, &:active, &:hover {
                                $menu-tab-width: 4px;
                                border-bottom: none;
                                padding-top: 0;
                            }

                            &.active, &:active {
                                & > div, & > .menu-title {
                                    @extend .text-color-nav-active;
                                }
                            }

                            &.dropdown:hover + .submenu,
                            &.dropdown:hover > .submenu {
                                opacity: 100;
                                visibility: visible;
                            }

                            .submenu {
                                background-color: $color-black;
                                opacity: 0;
                                visibility: hidden;
                                @extend .flex;
                                @extend .justify-end;
                                position: absolute;
                                top: $size-nav-height / 2;
                                left: 0;
                                display: block;
                                min-width: 100%;
                                padding: 0px 0;
                                transition: opacity $transition-speed-very-fast ease-in;
                                z-index: $z-index-submenu;
                                @media screen and (max-width: $size-nav-shift-breakpoint) {
                                    top: $size-nav-height-mobile / 2;
                                }
                                ul {
                                    @extend .flex;
                                    @extend .flex-column;
                                    @extend .justify-start;
                                    flex: 1 1 auto;
                                    margin-top: 0;
                                    @extend .p0;

                                    .subitem {
                                        padding: 5px 0;
                                        position: relative;
                                        white-space: nowrap;

                                        > a {
                                            display: flex;
                                            @extend .justify-between;
                                            @extend .items-center;
                                            min-height: 30px;
                                            width: 100%;

                                            span {
                                            }

                                            i {
                                                top: -1px;
                                                font-size: 0.8rem;
                                            }
                                        }

                                        &:hover {
                                            @extend .bg-color-nav-hover;
                                            border: none;

                                            div, span, i {
                                                @extend .text-color-nav-hover;
                                            }

                                            & > .subitem-menu {
                                                display: block;
                                            }

                                        }

                                        &:active, &.active {
                                            //@extend .bg-color-nav-active;
                                            border: none;

                                            & > a, & > a > div {
                                                @extend .text-color-nav-active;
                                            }
                                        }

                                        & > a {
                                            @extend .font-nav-drop-items;
                                            @extend .text-color-nav;
                                        }

                                        //menu 2eme niveau
                                        & > .subitem-menu {
                                            position: absolute;
                                            left: 100%;
                                            top: 0;
                                            display: none;
                                            background-color: $color-black;

                                            .subitem {
                                                white-space: nowrap;

                                                & > a {
                                                    //font-weight: 400!important;
                                                    @extend .font-nav-drop-items;
                                                    @extend .text-color-nav;

                                                    div {
                                                        @extend .text-color-nav;
                                                    }
                                                }

                                                &:hover {
                                                    & > a div {
                                                        @extend .text-color-nav-hover;
                                                    }
                                                }

                                                &:active, &.active {
                                                    //@extend .bg-color-nav-active;
                                                    border: none;

                                                    & > a div {
                                                        @extend .text-color-nav-active;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /************ menu toggle ***************************/
    .menu-toggle {
        display: none;
        @media screen and (max-width: $size-nav-toggle-breakpoint) {
            display: block;
        }
        cursor: pointer;
        @extend .pr3;

        .bar-top, .bar-bottom {
            // box-shadow: 0px 0px 4px $color-black;
            width: 100%;
            height: $size-nav-toggle-bar-height;
            display: block;
            @extend .bg-color-nav-toggle;
        }

        .bar-top {
             margin-bottom: 3px;
        }

        .bar-bottom {
             margin-top: 1px;
        }

        .word {
            // text-shadow: 0px 0px 6px white;
            @extend .font-1-normal;
            @extend .text-color-nav-toggle;
        }
    }

    /************ menu mobile ***************************/
    #menu-mobile {
        width: 100%;
        top: 0;
        right: -100%;
        z-index: $z-index-menu-mobile;
        // overflow: hidden;
        position: fixed;
        @extend .bg-color-nav-toggle-backdrop;
        transition: all $transition-speed-fast ease-in;

        &.toggled {
            overflow-y: auto;
            bottom: 0; // pour avoir le scroll
            transform: translate(-100%, 0%);
        }

        .menu-toggle {
            float: right;
            margin: 15px 0 0 0;

            .word {
                @extend .text-color-nav;
            }
        }

        ul {
            margin: 25px auto;
            padding: 30px;

            li {
                // position: relative;
                text-align: center;
                line-height: 100%;
                padding: 15px;
                border-bottom: 1px solid #0054a6;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                &.top-bar {
                    display: flex;
                    @extend .flex-wrap;
                    @extend .justify-between;
                    @extend .items-center;

                    div.icons {
                        flex: 1 1;

                        & > a {
                            img {
                                margin: 5px;
                            }
                        }
                    }

                    a {
                        flex: 1 1;
                    }
                }

                & > a, & > .menu-title {
                    @extend .font-nav-items;
                    @extend .text-color-nav;
                }

                .submenu {
                    margin: 0px auto;
                    padding: 10px 0 10px;

                    ul {
                        margin: 0;
                        padding: 0;

                        li.subitem {
                            border-bottom: none;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            .menu-title {
                                margin-right: 20px;
                                font-weight: 700;

                            }
                            ul {
                                border-left: 1px $line-color-nav-mobile solid;
                                li {
                                    padding: 5px 20px;
                                }
                            }
                            a, a div {
                                @extend .font-nav-drop-items;
                                @extend .text-color-nav;
                                font-weight: 300;
                            }

                            &:hover {
                                > a, > a div {
                                    @extend .text-color-nav-hover;
                                }
                            }

                            &.active {
                                a, a div {
                                    @extend .text-color-nav-active;
                                }
                            }
                        }
                    }

                }

                &:hover {
                    > a, > a div {
                        @extend .text-color-nav-hover;
                    }
                }

                &.active, &.phone, &.lang {
                    > a, > a div {
                        color: $color-white;
                    }
                }
            }
        }
    }

}
